import useSWR from "swr"
import { IAdvisorProductType, IPromotionAdvisor } from "../../../types"
import { fetchWithConfig } from "../../../utils/rest"
import { getRuntimeConfig } from "../../../utils/runtimeConfig"

interface IUseFetchPromotionsResult {
  loading: boolean
  promotions: IPromotionAdvisor[]
  activePromotion?: IPromotionAdvisor
  hasOnlyMarketplacePromotions: boolean
}

interface IUseFetchPromotionsConfig {
  advisor_id?: number
  product?: IAdvisorProductType
}

const usePromotions = (
  config?: IUseFetchPromotionsConfig,
  isCallable = true,
  activePromotionId?: number
): IUseFetchPromotionsResult => {
  const key = isCallable ? { ...(config ?? {}), url: "promotions" } : null
  const { data, isLoading, isValidating } = useSWR<{
    list: Array<IPromotionAdvisor>
  }>(
    isCallable ? key : null,
    (key: Record<string, any>) => {
      const { url, headers, ...rest } = key

      return fetchWithConfig<{ list: Array<IPromotionAdvisor> }>(
        {
          url,
          headers,
          data: rest,
          method: "POST",
        },
        getRuntimeConfig,
        "advice"
      )
    },
    { revalidateOnFocus: false }
  )

  const promotions = data?.list ?? []

  return {
    loading: isLoading || isValidating,
    promotions,
    activePromotion: promotions.find(({ id }) => id === activePromotionId),
    hasOnlyMarketplacePromotions:
      promotions.length > 0 &&
      promotions.every(({ advisor }) => advisor === null),
  }
}

export default usePromotions
