import { AdviqoButton, Carousel, StarIcon } from "../../_common"
import Base from "./Base"
import baseClasses from "./Base.module.scss"
import ExpertItem from "../ExpertItem"
import useRateFlowContext from "../context/useRateFlowContext"
import { useTranslations } from "next-intl"
import classes from "./Popup.module.scss"
import { IRateItem } from "../types"

interface IPopupProps {
  items: IRateItem[]
  onClick?: (item: IRateItem | undefined) => void
}

const Popup: React.FC<IPopupProps> = ({ items, onClick }) => {
  const { followNextStep } = useRateFlowContext()

  const translate = useTranslations()

  return (
    <Carousel
      itemsLength={items.length}
      arrowsClassName={classes.carouselArrows}
    >
      {items.map((rateItem) => (
        <Base
          key={rateItem.expert.id}
          title={translate("rating.steps.popup.title", {
            product: translate(`product.${rateItem.session_type}`),
            name: rateItem.expert.name,
          })}
          renderCta={
            <AdviqoButton
              label={translate("rating.cta.rate")}
              labelCentered
              testId="rate-flow-next-step-button"
              onClick={() => {
                if (onClick) {
                  onClick(rateItem)
                } else {
                  followNextStep("rate")
                }
              }}
              labelClassName={baseClasses.fontCommon}
              buttonClassName={baseClasses.cta}
              icon={<StarIcon />}
            />
          }
          renderExpertItem={
            <ExpertItem
              name={rateItem.expert.name}
              avatar={rateItem.expert.avatar}
            />
          }
        />
      ))}
    </Carousel>
  )
}

export default Popup
