import { AdviqoButton } from "../../_common"
import Base from "./Base"
import classes from "./Error.module.scss"
import baseClasses from "./Base.module.scss"
import { useTranslations } from "next-intl"
import { getBrandDashboardSlug } from "../../../utils/utils"
import ErrorCircleIcon from "../../_common/QuesticoIcon/ErrorCircleIcon"

interface IErrorProps {
  withCta: boolean
}

const Error: React.FC<IErrorProps> = ({ withCta }) => {
  const translate = useTranslations()

  return (
    <Base
      title={translate.rich("rating.steps.error.title", {
        break: () => <br />,
      })}
      renderIcon={
        <span className={classes.icon}>
          <ErrorCircleIcon width={50} height={50} />
        </span>
      }
      renderCta={
        withCta ? (
          <AdviqoButton
            label={translate("rating.cta.backHome", {
              brand: translate("brandName"),
            })}
            labelCentered
            testId="rate-flow-next-step-button"
            labelClassName={baseClasses.fontCommon}
            buttonClassName={baseClasses.cta}
            to={`/${getBrandDashboardSlug()}`}
          />
        ) : null
      }
    />
  )
}

export default Error
