import { useEffect, useRef, useState } from "react"

const useDetectKeyboardOpen = (
  minKeyboardHeight = 300,
  defaultValue = false
) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue)
  const visualViewportHeight = useRef(window.visualViewport?.height)

  useEffect(() => {
    const listener = () => {
      if (!window.visualViewport) return

      visualViewportHeight.current = window.visualViewport.height

      const newState =
        window.screen.height - minKeyboardHeight > window.visualViewport.height

      setIsKeyboardOpen(newState)
    }

    window.visualViewport?.addEventListener("resize", listener)

    return () => {
      window.visualViewport?.removeEventListener("resize", listener)
    }
  }, [])

  return { isKeyboardOpen, visualViewportHeight: visualViewportHeight.current }
}

export default useDetectKeyboardOpen
