import React from "react"

interface IConditionalWrapProps {
  condition: boolean
  wrap: (children: JSX.Element) => JSX.Element
  children: React.ReactNode
}

const ConditionalWrap: React.FC<IConditionalWrapProps> = ({
  condition,
  wrap,
  children,
}) => (condition ? wrap(<>{children}</>) : <>{children}</>)

export default ConditionalWrap
