"use client"

import RateFlow from "./RateFlow"
import { useCallback, useState } from "react"
import { PopupStep } from "./Steps"
import {
  IAdvisor,
  IReviewsRequests,
  TReviewsRequestsStatusForPayload,
} from "../../types"
import { IRateItem, TStepType } from "./types"
import { Paper } from "../_common"
import useSWR, { BareFetcher } from "swr"
import { getRuntimeConfig } from "utils/runtimeConfig"
import { fetchWithConfig } from "utils/rest"
import useRatingApi from "./hooks/useRatingApi"

interface IRateCollectionProps {
  className?: string
}

const RateCollection: React.FC<IRateCollectionProps> = ({ className }) => {
  const [rateItem, setRateItem] = useState<IRateItem | undefined>()

  const { updateReviewStatus } = useRatingApi()

  const {
    data: reviewsList,
    isLoading: reviewsLoading,
    mutate: reviewsRefetch,
  } = useSWR<{
    list: IReviewsRequests[]
  }>("review-requests", ((_, { headers }) => {
    return fetchWithConfig(
      {
        url: "review-requests",
        data: {
          count: 10000,
          // eslint-disable-next-line camelcase
          order_by: "created_at",
          // eslint-disable-next-line camelcase
          order_dir: "desc",
          offset: 0,
        },
        method: "POST",
        headers,
      },
      getRuntimeConfig,
      "advice"
    )
  }) as BareFetcher<{ list: IReviewsRequests[] }>)

  const { list: reviews = [] } = reviewsList || {}

  const reviewsExist = reviews.length > 0

  const { data: expertsList, isLoading: expertsLoading } = useSWR<{
    list: IAdvisor[]
  }>(reviewsExist ? "review-requests-advisors" : null, ((_, { headers }) => {
    return fetchWithConfig(
      {
        url: "advisor/list",
        data: {
          ids: reviews.map((it) => it.advisor_id),
          count: reviews.length,
        },
        method: "POST",
        headers,
      },
      getRuntimeConfig,
      "advice"
    )
  }) as BareFetcher<{ list: IAdvisor[] }>)

  const onModalClose = useCallback(
    async (step: TStepType) => {
      if (!rateItem) {
        return
      }

      if (step !== "thankYou" && step !== "postpone") {
        await updateReviewStatus({
          id: rateItem.id,
          status: "skipped",
        })
      }

      setRateItem(undefined)
    },
    [rateItem, updateReviewStatus]
  )

  if (!rateItem && (reviewsLoading || expertsLoading)) {
    return (
      <div className={className}>
        <Paper loading />
      </div>
    )
  }

  const { list: experts = [] } = expertsList || {}

  const expertsIds = experts.map((exp) => exp.id)

  const expertsExist = expertsIds.length > 0

  if (!rateItem && !expertsExist) {
    return null
  }

  const reviewsExperts = reviews
    .filter((it) => expertsIds.includes(it.advisor_id))
    .map((it) => ({
      id: it.id,
      session_id: it.session_id,
      session_type: it.session_type,
      expert: experts.find((exp) => exp.id === it.advisor_id)!,
      status: it.status,
    }))

  return (
    <div className={className}>
      <PopupStep items={reviewsExperts} onClick={setRateItem} />

      {rateItem && (
        <RateFlow
          status={rateItem.status as TReviewsRequestsStatusForPayload}
          step="rate"
          consultationId={rateItem.session_id.toString()}
          product={rateItem.session_type}
          expert={rateItem.expert}
          withExpertItem
          isOpened={Boolean(rateItem)}
          onModalClose={onModalClose}
          mode="popup"
          loading={false}
          reviewRequestId={rateItem.id}
          onRateFinish={(step) => {
            if (step === "thankYou") {
              reviewsRefetch()
            }
          }}
          isDashboard={true}
        />
      )}
    </div>
  )
}

export default RateCollection
