import clsx from "clsx"
import Avatar from "../Experts/Avatar"
import classes from "./ExpertItem.module.scss"
import flexGridClasses from "@styles/grid.module.scss"

interface IExpertItemProps {
  name: string
  avatar: string
}

const ExpertItem: React.FC<IExpertItemProps> = ({ name, avatar }) => {
  return (
    <div
      className={clsx(
        flexGridClasses.group,
        flexGridClasses.flexDirectionColumn,
        flexGridClasses.alignItemsCenter
      )}
    >
      <div className={classes.avatarWrapper}>
        <Avatar photo={avatar} displayName={name} />
      </div>

      <h4 className={classes.name}>{name}</h4>
    </div>
  )
}

export default ExpertItem
