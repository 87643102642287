import { IAdvisor } from "../../../../types"
import { IAdvisorListingFetchData, IUseFetchAdvisorsResult } from "./types"
import useFetchDataByPage from "../../../../hooks/api/useFetchDataByPage"

const useFetchExperts = (
  operationName: string,
  fetchExpertsConfig: IAdvisorListingFetchData,
  skipFetch = false
): IUseFetchAdvisorsResult => {
  const {
    data: experts,
    isLoading,
    loadMore,
    isLoadingMore,
    error,
    refetch,
    queryPage,
  } = useFetchDataByPage<IAdvisor, IAdvisorListingFetchData>({
    url: "advisor/list",
    operationName,
    payload: fetchExpertsConfig,
    skipFetch,
    withExcludedIds: true,
  })

  return {
    experts,
    loading: isLoading,
    error,
    currentPageNumber: queryPage,
    loadMore,
    refetch,
    isLoadingMore,
  }
}

export default useFetchExperts
