"use client"

import { useTranslations } from "next-intl"
import { useProductService } from "../../hooks/productService"
import { Snackbar } from "../../ui-lib/_common"

const RateReminder = () => {
  const { shouldShowRatingReminder } = useProductService()

  const translate = useTranslations()

  if (!shouldShowRatingReminder) return null

  return (
    <Snackbar
      isOpen
      closingComponent="icon"
      preventCloseOnClickAway
      autoHideDuration={10000}
    >
      {translate("rating.steps.postpone.text")}
    </Snackbar>
  )
}

export default RateReminder
