"use client"

import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useCallback, useEffect, useRef } from "react"
import useSWRInfinite from "swr/infinite"
import { fetchWithConfig } from "../../utils/rest"
import { IFetchResultByPage, IFetchResultByPageContent } from "./types"
import { fetchResultByPage } from "./utils"
import { useRuntimeConfig } from "../runtimeConfig"
import { usePrevious } from "../usePrevious"
import { useAuthentication } from "../authentication"
import cachedToken from "../../utils/graphql/cachedToken"

const PAGE_SIZE = 20

interface IUseFetchDataByPage<TData> {
  data: IFetchResultByPageContent<TData>
  isLoading: boolean
  isLoadingMore: boolean
  isRefreshing: boolean
  loadMore: () => void
  error: unknown
  refetch: () => void
  queryPage: number
}

interface IBasePayload {
  size: number
}

type IPageDataGenericItem = {
  id: number
}

interface IUseFetchDataByPageOptions<TPayload> {
  url: string
  /**
   * used to make request more unique
   */
  operationName: string
  payload: TPayload
  skipFetch?: boolean
  withExcludedIds?: boolean
}

const useFetchDataByPage = <
  TData extends IPageDataGenericItem,
  TPayload extends IBasePayload = {
    size: number
  }
>(
  options: IUseFetchDataByPageOptions<TPayload>
): IUseFetchDataByPage<TData> => {
  const {
    url,
    payload,
    operationName,
    skipFetch = false,
    withExcludedIds = false,
  } = options

  const { replace } = useRouter()
  const searchParams = useSearchParams()
  const path = usePathname()

  const { getRuntimeConfig } = useRuntimeConfig()

  const { isAuthenticated } = useAuthentication()

  const prevIsAuthenticated = usePrevious(isAuthenticated)

  const isLoggedOut =
    prevIsAuthenticated !== undefined && prevIsAuthenticated !== isAuthenticated

  const queryPage = Number(searchParams.get("page") ?? 1)
  const initPage = useRef(queryPage)?.current

  const { size: count, ...rest } = payload

  const pageCount = count || PAGE_SIZE

  const ids = useRef<number[]>([])

  const {
    data: _data,
    isLoading,
    size,
    setSize,
    isValidating,
    error,
    mutate,
  } = useSWRInfinite<IFetchResultByPage<TData>>(
    (index, previousPageData: IFetchResultByPage<TData>) => {
      if (skipFetch || (previousPageData && !previousPageData.list.length)) {
        return null
      }

      return {
        operationName,
        /**
         * Unique key for caching request data.
         * Need to avoid taking wrong data from cache.
         *
         * Bug ticket for reference:
         * https://adviqo.atlassian.net/browse/MISSION-3015
         */
        userId: cachedToken.token || "anonymous",
        index,
        previousPageDataIds: previousPageData?.list?.map((it) => it.id) || [],
        data: rest,
      }
    },
    ({ data, index, previousPageDataIds, headers }) => {
      ids.current = [...new Set([...ids.current, ...previousPageDataIds])]

      return fetchWithConfig(
        {
          url,
          method: "POST",
          data: {
            ...data,
            count: pageCount,
            /**
             * We either pass excluded_ids or offset
             */
            ...(!withExcludedIds && { offset: index * pageCount }),
            ...(withExcludedIds && { excluded_ids: ids.current }),
          },
          headers,
        },
        getRuntimeConfig,
        "advice"
      )
    },
    {
      initialSize: initPage,
      revalidateFirstPage: false,
    }
  )

  const data = fetchResultByPage<TData>(_data?.filter(Boolean))

  const loadMore = useCallback(() => {
    const nextPage = size + 1
    setSize(nextPage).then(() => {
      const currentParams = new URLSearchParams(searchParams)
      currentParams.set("page", nextPage.toString())
      replace(`${path}?${currentParams}`, { scroll: false })
    })
  }, [path, searchParams, replace, setSize, size])

  const refetch = useCallback(() => {
    ids.current = []
    mutate()
  }, [mutate])

  useEffect(() => {
    if (isLoggedOut) {
      refetch()
    }
  }, [isLoggedOut, refetch])

  return {
    data,
    isLoading,
    isLoadingMore: isValidating,
    isRefreshing: isValidating && data && _data?.length === size,
    loadMore,
    error,
    refetch,
    queryPage,
  }
}

export default useFetchDataByPage
