import { AdviqoButton } from "../../_common"
import Base from "./Base"
import classes from "./Postpone.module.scss"
import baseClasses from "./Base.module.scss"
import TwRelaxedFace from "../../_common/QuesticoIcon/TwRelaxedFace"
import { useTranslations } from "next-intl"
import { getBrandDashboardSlug } from "../../../utils/utils"

interface IPostponeProps {
  withCta: boolean
}

const Postpone: React.FC<IPostponeProps> = ({ withCta }) => {
  const translate = useTranslations()

  return (
    <Base
      title={translate("rating.steps.postpone.text")}
      renderIcon={
        <span className={classes.icon}>
          <TwRelaxedFace width={36} height={36} />
        </span>
      }
      renderCta={
        withCta ? null : (
          <AdviqoButton
            label={translate("rating.cta.home", {
              brand: translate("brandName"),
            })}
            labelCentered
            testId="rate-flow-next-step-button"
            labelClassName={baseClasses.fontCommon}
            buttonClassName={baseClasses.cta}
            to={`/${getBrandDashboardSlug()}`}
          />
        )
      }
    />
  )
}

export default Postpone
