"use client"

import { usePathname } from "next/navigation"
import RateFlow from "./RateFlow"
import { useEffect, useState } from "react"
import { useAuthentication } from "../../hooks/authentication"
import { usePrevious } from "../../hooks/usePrevious"
import { useFetchExpertProfileAPI } from "../../ui-lib/Experts/ExpertsService"
import useFetchDataByPage from "../../hooks/api/useFetchDataByPage"
import {
  ClientTrackingEventName,
  IReviewsRequests,
  TReviewsRequestsStatusForPayload,
} from "../../types"
import { IReviewsRequestsPayload } from "./types"
import useRatingApi from "./hooks/useRatingApi"
import { getBrandDashboardSlug } from "../../utils/utils"
import { useTracking } from "../../hooks/tracking"

const RateFlowModal: React.FC = () => {
  const { isAuthenticated } = useAuthentication()

  const [shouldQueryReviews, setShouldQueryReviews] = useState(false)

  const { trackEvent } = useTracking()

  const { updateReviewStatus } = useRatingApi()

  const prevIsAuthenticated = usePrevious(isAuthenticated)

  const isJustAuthenticated =
    prevIsAuthenticated !== undefined &&
    isAuthenticated !== prevIsAuthenticated &&
    isAuthenticated

  const pathname = usePathname()
  const isProtectedPath = pathname.includes(`/${getBrandDashboardSlug()}`)

  useEffect(() => {
    if (isJustAuthenticated) {
      setShouldQueryReviews(true)
    }
  }, [isJustAuthenticated])

  useEffect(() => {
    if (isProtectedPath) {
      setShouldQueryReviews(false)
    }
  }, [isProtectedPath])

  const { data } = useFetchDataByPage<
    IReviewsRequests,
    IReviewsRequestsPayload
  >({
    url: "review-requests",
    operationName: "review-requests",
    payload: {
      size: 1,
      status: "postponed",
      // eslint-disable-next-line camelcase
      order_by: "created_at",
      // eslint-disable-next-line camelcase
      order_dir: "desc",
      offset: 0,
    },
    skipFetch: !shouldQueryReviews,
  })

  const [reviewRequest] = data.content

  const { expert } = useFetchExpertProfileAPI({
    advisorId: reviewRequest?.advisor_id,
  })

  useEffect(() => {
    if (reviewRequest?.id && expert?.advisor.id) {
      trackEvent({
        eventName: ClientTrackingEventName.RATING_POPUP_VIEWED,
      })
    }
  }, [expert?.advisor.id, reviewRequest?.id, trackEvent])

  if (!reviewRequest || !expert) {
    return null
  }

  return (
    <RateFlow
      status={reviewRequest.status as TReviewsRequestsStatusForPayload}
      id="rateFlowModal"
      step="popup"
      consultationId={reviewRequest.session_id.toString()}
      product={reviewRequest.session_type}
      expert={expert.advisor}
      withExpertItem
      mode="popup"
      isOpened={true}
      loading={false}
      reviewRequestId={reviewRequest.id}
      onModalClose={(step) => {
        if (step !== "thankYou" && step !== "postpone") {
          updateReviewStatus({
            id: reviewRequest.id,
            status: "skipped",
          })
        }
      }}
    />
  )
}

export default RateFlowModal
