import { AdviqoButton, AdviqoLink } from "../../_common"
import Base from "./Base"
import classes from "./Info.module.scss"
import baseClasses from "./Base.module.scss"
import SuccessCircleIcon from "../../_common/QuesticoIcon/SuccessCircleIcon"
import { useTranslations } from "next-intl"
import { getBrandDashboardSlug } from "../../../utils/utils"

const Info: React.FC = () => {
  const translate = useTranslations()

  return (
    <Base
      title={translate.rich("rating.steps.info.title", {
        break: () => <br />,
      })}
      text={
        <>
          {translate.rich("rating.steps.info.text", {
            break: () => <br />,
          })}{" "}
          <AdviqoLink to="/bewertungskodex" className={classes.link}>
            {translate("rating.cta.learnMore")}
          </AdviqoLink>
        </>
      }
      renderIcon={
        <span className={classes.icon}>
          <SuccessCircleIcon width={50} height={50} />
        </span>
      }
      renderCta={
        <AdviqoButton
          label={translate("rating.cta.backHome", {
            brand: translate("brandName"),
          })}
          labelCentered
          testId="rate-flow-next-step-button"
          labelClassName={baseClasses.fontCommon}
          buttonClassName={baseClasses.cta}
          to={`/${getBrandDashboardSlug()}`}
        />
      }
    />
  )
}

export default Info
