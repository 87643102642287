"use client"

import { useCallback, useState } from "react"
import { AdviqoButton, LinkButton, Rating, StarIcon } from "../../_common"
import Base from "./Base"
import { useTranslations } from "next-intl"
import classes from "./Rate.module.scss"
import baseClasses from "./Base.module.scss"
import clsx from "clsx"
import ExpertItem from "../ExpertItem"
import useRateFlowContext from "../context/useRateFlowContext"
import { IAdvisorProductType } from "../../../types"
import useRatingApi from "../hooks/useRatingApi"

interface IRateProps {
  product: IAdvisorProductType
  name: string
  avatar: string
  withExpertItem: boolean
  reviewRequestId: number
}

const Rate: React.FC<IRateProps> = ({
  product,
  avatar,
  name,
  withExpertItem,
  reviewRequestId,
}) => {
  const [rating, setRating] = useState(0)

  const isInitialStep = rating === 0

  const translate = useTranslations()

  const { updateReviewStatus } = useRatingApi()

  const { followNextStep } = useRateFlowContext()

  const ratingTextClasses = clsx(baseClasses.fontCommon, classes.ratingText, {
    [classes.hidden]: isInitialStep,
  })

  const onClick = useCallback(async () => {
    try {
      await updateReviewStatus({
        id: reviewRequestId,
        status: "postponed",
      })

      followNextStep("postpone")
    } catch (error) {
      followNextStep("error")
    }
  }, [followNextStep, reviewRequestId, updateReviewStatus])

  return (
    <Base
      title={translate("rating.steps.rate.title", {
        product: translate(`product.${product}`),
        name,
      })}
      renderCta={
        isInitialStep ? (
          <LinkButton
            labelClassName={clsx(baseClasses.fontCommon, baseClasses.linkCta)}
            label={translate("rating.cta.postpone")}
            testId="rate-flow-skip-step-button"
            onClick={onClick}
          />
        ) : (
          <AdviqoButton
            label={translate("rating.cta.next")}
            onClick={() => followNextStep("feedback", rating)}
            labelCentered
            testId="rate-flow-next-step-button"
            labelClassName={baseClasses.fontCommon}
            buttonClassName={baseClasses.cta}
          />
        )
      }
      {...(withExpertItem && {
        renderExpertItem: <ExpertItem name={name} avatar={avatar} />,
      })}
    >
      <Rating
        classes={{
          icon: classes.starWrapper,
          iconHover: classes.starWrapper,
        }}
        value={rating}
        onChange={(_, newVal) => {
          if (newVal) {
            setRating(newVal)
          }
        }}
        precision={1}
        emptyIcon={<StarIcon fontSize="inherit" />}
        data-testid="rate-flow-rating"
      />

      <div className={ratingTextClasses} data-testid="rate-flow-rating-text">
        {`"${translate(`rating.stars.${rating}`)}"`}
      </div>
    </Base>
  )
}

export default Rate
