export function getReviewBody(
  title: string | undefined,
  text: string | undefined
) {
  let body: string | null = null

  if (title) body = `Title: ${title};`
  if (text) body = body ? `${body}Text: ${text};` : `Text: ${text};`

  return body
}
