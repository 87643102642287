import { IFetchResultByPage, IFetchResultByPageContent } from "./types"

export function fetchResultByPage<T>(
  data: IFetchResultByPage<T>[] | undefined
): IFetchResultByPageContent<T> {
  if (!data || !data?.filter(Boolean).length) {
    return { content: [], isLast: true }
  }

  const list = data.reduce((acc: any, item) => {
    return [...acc, ...item.list]
  }, [])

  const isLast = !data[data.length - 1].left

  return {
    content: list,
    isLast,
  }
}
