import { createContext } from "react"
import { TRateFlowMode, TStepType } from "../types"

interface IRateFlowContext {
  rating: number
  step: TStepType
  followNextStep: (newStep: TStepType, newRating?: number) => void
  mode: TRateFlowMode
}

export default createContext<IRateFlowContext>({
  rating: 0,
  step: "popup",
  mode: "product",
  followNextStep: () => ({}),
})
