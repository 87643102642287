"use client"

import { ComponentProps, ReactNode, useEffect, useState } from "react"
import {
  FeedbackStep,
  PopupStep,
  RateStep,
  PostponeStep,
  ThankYouStep,
  ErrorStep,
} from "./Steps"
import { TRateFlowMode, TStepType } from "./types"
import ConditionalWrap from "../ConditionalWrap/ConditionalWrap"
import { ModalBase, Paper } from "../_common"
import classes from "./RateFlow.module.scss"
import RateFlowProvider from "./context/RateFlowProvider"
import useRateFlowContext from "./context/useRateFlowContext"
import {
  IAdvisor,
  IAdvisorProductType,
  TReviewsRequestsStatusForPayload,
  Replace,
} from "../../types"
import clsx from "clsx"
import { useDomRefs } from "../../hooks/domRefs"

interface IRateFlowProps {
  id?: string
  product: IAdvisorProductType
  expert: IAdvisor | undefined
  step: TStepType
  isOpened?: boolean
  withExpertItem: boolean
  onModalClose?: (step: TStepType) => void
  mode: TRateFlowMode
  reviewRequestId: number | undefined
  loading: boolean
  onRateFinish?: (step: TStepType) => void
  isDashboard?: boolean
  status: TReviewsRequestsStatusForPayload
  consultationId: string | null | undefined
}

const RateSteps: React.FC<Replace<
  Omit<IRateFlowProps, "isOpened" | "mode" | "loading">,
  "expert" | "reviewRequestId" | "isDashboard" | "consultationId",
  {
    expert: IAdvisor
    reviewRequestId: number
    isDashboard: boolean
    consultationId: string
  }
>> = ({
  product,
  expert,
  withExpertItem,
  reviewRequestId,
  onRateFinish,
  isDashboard,
  status,
  consultationId,
}) => {
  const { step, mode } = useRateFlowContext()

  switch (step) {
    case "popup": {
      return (
        <PopupStep
          items={[
            {
              id: reviewRequestId,
              session_id: Number(consultationId),
              session_type: product,
              expert,
              status,
            },
          ]}
        />
      )
    }

    case "rate": {
      return (
        <RateStep
          product={product}
          avatar={expert.avatar}
          name={expert.name}
          withExpertItem={withExpertItem}
          reviewRequestId={reviewRequestId}
        />
      )
    }

    case "feedback": {
      return (
        <FeedbackStep
          expertName={expert.name}
          listingNumber={expert.listing_number}
          reviewRequestId={reviewRequestId}
          product={product}
          onRateFinish={onRateFinish}
          consultationId={consultationId}
        />
      )
    }

    case "thankYou": {
      return <ThankYouStep withCta={mode === "product"} />
    }

    case "postpone": {
      return <PostponeStep withCta={isDashboard} />
    }

    case "error": {
      return <ErrorStep withCta={mode === "product"} />
    }

    default:
      return null
  }
}

type IRateModalProps = Pick<ComponentProps<typeof ModalBase>, "opened"> & {
  id?: string
  closeModal: (step: TStepType) => void
  children?: ReactNode
}

const RateModal: React.FC<IRateModalProps> = ({
  id,
  children,
  opened,
  closeModal,
}) => {
  const { step } = useRateFlowContext()

  const { refsMap } = useDomRefs()

  const className = clsx(classes.modalRoot, {
    [classes.modalAsSnackbar]: step === "popup",
  })

  return (
    <ModalBase
      id={id}
      opened={opened}
      closeModal={() => {
        closeModal(step)
      }}
      wrapperClassName={classes.modal}
      rootClassName={className}
      canCloseOnBackdrop={false}
      {...(step === "popup" && {
        modalProps: {
          disableEnforceFocus: true,
          disableScrollLock: true,
          disableEscapeKeyDown: true,
          slotProps: {
            backdrop: {
              className: classes.backdrop,
            },
          },
          sx: {
            bottom: `${
              16 +
              (refsMap?.promotionsSnackbar?.current?.offsetHeight
                ? refsMap.promotionsSnackbar.current.offsetHeight + 16
                : 0)
            }px`,
          },
        },
      })}
    >
      {children}
    </ModalBase>
  )
}

const RateFlow: React.FC<IRateFlowProps> = ({
  id,
  product,
  expert,
  step,
  isOpened = false,
  withExpertItem = false,
  onModalClose,
  mode,
  reviewRequestId,
  loading,
  onRateFinish,
  isDashboard = false,
  status,
  consultationId,
}) => {
  const [opened, setOpened] = useState(isOpened)
  const [postponed, setPostponed] = useState(false)

  useEffect(() => {
    setOpened(isOpened)
  }, [isOpened])

  if (loading) {
    return <Paper loading />
  }

  if (!expert || !reviewRequestId || !consultationId) {
    return <ErrorStep withCta={mode === "product"} />
  }

  return (
    <RateFlowProvider initialStep={step} mode={mode}>
      <ConditionalWrap
        condition={mode === "popup"}
        wrap={(children) => (
          <RateModal
            id={id}
            opened={opened && !postponed}
            closeModal={(step) => {
              setOpened(false)
              setPostponed(true)
              if (onModalClose) {
                onModalClose(step)
              }
            }}
          >
            {children}
          </RateModal>
        )}
      >
        <RateSteps
          product={product}
          step={step}
          expert={expert}
          withExpertItem={withExpertItem}
          reviewRequestId={reviewRequestId}
          onRateFinish={onRateFinish}
          isDashboard={isDashboard}
          status={status}
          consultationId={consultationId}
        />
      </ConditionalWrap>
    </RateFlowProvider>
  )
}

export default RateFlow
