"use client"

import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import RateFlowContext from "./RateFlowContext"
import { TRateFlowMode, TStepType } from "../types"
import { useProductService } from "../../../hooks/productService"

interface IFiltersProviderProps {
  initialStep: TStepType
  mode: TRateFlowMode
  children: ReactNode
}

const RateFlowProvider: React.FC<IFiltersProviderProps> = ({
  initialStep,
  mode,
  children,
}) => {
  const [rating, setRating] = useState(0)

  const [step, setStep] = useState(initialStep)

  /**
   * @note Need for running the cleanup function of useEffect only once
   * when the component unmounts, but the cleanup function's behavior
   * depends on the step value, so we can capture the step
   * value in a ref and use it in the cleanup function. */
  const stepRef = useRef(step)

  useEffect(() => {
    stepRef.current = step
  }, [step])

  const followNextStep = useCallback((newStep: TStepType, newRating = 0) => {
    setStep(newStep)

    if (newRating > 0) {
      setRating(newRating)
    }
  }, [])

  const { showRatingReminder } = useProductService()

  /** Warning: Strict Mode! In dev it will be fired immediately. */
  useEffect(() => {
    return () => {
      if (
        mode === "product" &&
        (stepRef.current === "rate" || stepRef.current === "feedback")
      ) {
        showRatingReminder()
      }
    }
  }, [])

  return (
    <RateFlowContext.Provider value={{ rating, step, followNextStep, mode }}>
      {children}
    </RateFlowContext.Provider>
  )
}

export default RateFlowProvider
