"use client"

import { useCallback, useEffect, useRef, useState } from "react"
import { AdviqoButton, Box, FieldStatusHelpText } from "../../_common"
import Base from "./Base"
import classes from "./Feedback.module.scss"
import baseClasses from "./Base.module.scss"
import flexGridClasses from "@styles/grid.module.scss"
import clsx from "clsx"
import useDebounce from "../../../hooks/useDebounce"
import useRateFlowContext from "../context/useRateFlowContext"
import { useTranslations } from "next-intl"
import useDetectKeyboardOpen from "../../../hooks/useDetectKeyboardOpen"
import useRatingApi from "../hooks/useRatingApi"
import { TStepType } from "../types"
import { IAdvisorProductType } from "../../../types"
import { useTracking } from "../../../hooks/tracking"
import { ClientTrackingEventName } from "../../../types"
import { getExpertTrackingProperties } from "../../../ui-lib/Experts/utils"
import { getRuntimeConfig } from "../../../utils/runtimeConfig"
import { getReviewBody } from "../utils"

const { MANDATOR_NUMBER } = getRuntimeConfig()

const TITLE_MAX_LENGTH = 120
const TEXT_MAX_LENGTH = 500
const INPUT_MIN_LENGTH = 2

interface IFeedbackProps {
  expertName: string
  listingNumber: string
  reviewRequestId: number
  product: IAdvisorProductType
  onRateFinish?: (step: TStepType) => void
  consultationId: string
}

type IFeedbackState = Record<string, string | undefined>

interface IInputInfoProps {
  isMinLengthError: boolean
  isMaxLengthError: boolean
  currentLength: number
  maxLength: number
}

const InputInfo: React.FC<IInputInfoProps> = ({
  isMinLengthError,
  isMaxLengthError,
  currentLength,
  maxLength,
}) => {
  const translate = useTranslations()

  return (
    <div
      className={clsx(
        flexGridClasses.displayFlex,
        flexGridClasses.justifyContentEnd,
        flexGridClasses.gap16
      )}
    >
      {isMinLengthError && (
        <FieldStatusHelpText variant="error">
          {translate("rating.steps.feedback.validation.minLength", {
            min: INPUT_MIN_LENGTH,
          })}
        </FieldStatusHelpText>
      )}

      {isMaxLengthError && (
        <FieldStatusHelpText
          variant="info"
          withIcon={false}
          className={classes.infoText}
        >
          {translate("rating.steps.feedback.validation.maxLength", {
            max: maxLength,
          })}
        </FieldStatusHelpText>
      )}

      <span>
        <span
          className={clsx({
            [classes.errorLength]: isMaxLengthError,
          })}
        >
          {currentLength}
        </span>
        {`/${maxLength}`}
      </span>
    </div>
  )
}

const Feedback: React.FC<IFeedbackProps> = ({
  product,
  listingNumber,
  expertName,
  reviewRequestId,
  onRateFinish,
  consultationId,
}) => {
  const { followNextStep, rating, mode } = useRateFlowContext()

  const { trackEvent } = useTracking()

  const { publishReview } = useRatingApi()

  const translate = useTranslations()

  const [feedback, setFeedback] = useState<IFeedbackState>({
    title: undefined,
    text: undefined,
  })

  const { title, text } = feedback

  const onChange = useCallback(
    (value: IFeedbackState) => {
      setFeedback({
        ...feedback,
        ...value,
      })
    },
    [feedback]
  )

  const debounceTitle = useDebounce(title, 300)
  const debounceText = useDebounce(text, 300)

  const isTitleMinLengthError = Boolean(
    debounceTitle && debounceTitle.length < INPUT_MIN_LENGTH
  )

  const isTitleMaxLengthError = title?.length === TITLE_MAX_LENGTH
  const isTextMaxLengthError = text?.length === TEXT_MAX_LENGTH

  const isTextMinLengthError = Boolean(
    debounceText && debounceText.length < INPUT_MIN_LENGTH
  )

  const { visualViewportHeight } = useDetectKeyboardOpen()

  const onClick = useCallback(async () => {
    try {
      await publishReview({
        reviews_request_id: reviewRequestId,
        rating,
        title,
        text,
      })

      followNextStep("thankYou")

      const { product_id: productId } = getExpertTrackingProperties(
        listingNumber,
        expertName,
        MANDATOR_NUMBER
      )

      trackEvent({
        eventName: ClientTrackingEventName.PRODUCT_REVIEWED,
        properties: {
          review_body: getReviewBody(title, text),
          review_id: consultationId,
          expert_id: productId,
          rating,
        },
      })

      if (onRateFinish) {
        onRateFinish("thankYou")
      }
    } catch (error) {
      followNextStep("error")
    }
  }, [
    consultationId,
    expertName,
    followNextStep,
    listingNumber,
    onRateFinish,
    publishReview,
    rating,
    reviewRequestId,
    text,
    title,
    trackEvent,
  ])

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const textarea = textareaRef.current

    const resize = () => {
      if (!textarea) return

      textarea.style.height = "auto"
      textarea.style.height = textarea.scrollHeight + "px"
    }

    textarea?.addEventListener("input", resize)
    return () => textarea?.removeEventListener("input", resize)
  }, [])

  const maxHeight =
    mode === "popup" && visualViewportHeight
      ? visualViewportHeight -
        16 * 2 - // paddings
        16 - // gap
        41 - // header
        36 // footer
      : "none"

  return (
    <Base
      renderCta={
        <AdviqoButton
          label={translate("rating.cta.send")}
          labelCentered
          testId="rate-flow-next-step-button"
          disabled={isTitleMinLengthError || isTextMinLengthError}
          onClick={onClick}
          labelClassName={baseClasses.fontCommon}
          buttonClassName={baseClasses.cta}
        />
      }
    >
      <Box
        className={classes.wrapper}
        sx={{
          maxHeight,
        }}
      >
        <div
          className={clsx(
            flexGridClasses.group,
            flexGridClasses.flexDirectionColumn,
            flexGridClasses.alignItemsCenter,
            flexGridClasses.gap16,
            classes.feedbackWrapper
          )}
        >
          <h2 className={clsx(baseClasses.fontCommon, baseClasses.title)}>
            {translate("rating.steps.feedback.title", {
              name: expertName,
              product: translate(`product.${product}`),
            })}
          </h2>

          <div
            className={clsx(
              classes.inputWrapper,
              flexGridClasses.displayFlex,
              flexGridClasses.flexDirectionColumn,
              flexGridClasses.gap4
            )}
          >
            <input
              type="text"
              placeholder={translate("rating.steps.feedback.placeholder.title")}
              className={classes.input}
              value={title}
              onChange={(e) =>
                onChange({
                  title: e.target.value,
                })
              }
              maxLength={TITLE_MAX_LENGTH}
            />

            <InputInfo
              isMinLengthError={isTitleMinLengthError}
              isMaxLengthError={isTitleMaxLengthError}
              currentLength={title?.length || 0}
              maxLength={TITLE_MAX_LENGTH}
            />
          </div>

          <div
            className={clsx(
              classes.inputWrapper,
              flexGridClasses.displayFlex,
              flexGridClasses.flexDirectionColumn,
              flexGridClasses.gap4
            )}
          >
            <textarea
              ref={textareaRef}
              data-testid="feedback-text"
              className={clsx(classes.input, classes.textarea)}
              placeholder={translate("rating.steps.feedback.placeholder.text")}
              onChange={(e) =>
                onChange({
                  text: e.target.value,
                })
              }
              value={text}
              maxLength={TEXT_MAX_LENGTH}
            />

            <InputInfo
              isMinLengthError={isTextMinLengthError}
              isMaxLengthError={isTextMaxLengthError}
              currentLength={text?.length || 0}
              maxLength={TEXT_MAX_LENGTH}
            />
          </div>
        </div>
      </Box>
    </Base>
  )
}

export default Feedback
