import { useContainerQuery } from "react-container-query"
import { ReactNode } from "react"
import flexGridClasses from "@styles/grid.module.scss"
import clsx from "clsx"
import classes from "./Base.module.scss"
import { StarIcon, Rating } from "../../_common"
import useRateFlowContext from "../context/useRateFlowContext"

const query = {
  widthForContainerQuery: {
    minWidth: 500,
  },
}

interface IBase {
  title?: ReactNode
  text?: ReactNode
  renderExpertItem?: ReactNode
  renderCta: ReactNode
  renderIcon?: ReactNode
  children?: ReactNode
}

const Base: React.FC<IBase> = ({
  title,
  text,
  renderCta,
  renderExpertItem,
  renderIcon,
  children,
}) => {
  const [containerParams, containerRef] = useContainerQuery(query, {})

  const { rating, step, mode } = useRateFlowContext()

  const rootClasses = clsx(
    flexGridClasses.group,
    flexGridClasses.flexDirectionColumn,
    flexGridClasses.justifyContentCenter,
    flexGridClasses.alignItemsCenter,
    flexGridClasses.gap16,
    classes.root,
    {
      [classes.widthForContainerQuery]: Boolean(
        containerParams.widthForContainerQuery
      ),
    }
  )

  const ctaClasses = clsx(classes.cta, {
    [classes.widthForContainerQuery]: Boolean(
      containerParams.widthForContainerQuery
    ),
  })

  const isRatingSet = rating > 0

  return (
    <article
      className={rootClasses}
      ref={containerRef}
      data-testid={`rate-flow-step-${step}`}
    >
      {isRatingSet && (
        <header
          className={clsx(
            flexGridClasses.alignSelfStretch,
            flexGridClasses.displayFlex,
            flexGridClasses.alignItemsCenter,
            flexGridClasses.flexDirectionColumn
          )}
        >
          <Rating
            size="small"
            value={rating}
            emptyIcon={<StarIcon fontSize="inherit" />}
            readOnly
          />

          <hr className={classes.hr} />
        </header>
      )}

      <main
        className={clsx(
          flexGridClasses.group,
          flexGridClasses.flexDirectionColumn,
          flexGridClasses.alignItemsCenter,
          flexGridClasses.alignSelfStretch,
          flexGridClasses.gap16,
          flexGridClasses.flexGrow1
        )}
      >
        {mode === "product" && renderIcon && renderIcon}

        {renderExpertItem && renderExpertItem}

        {Boolean(title || text) && (
          <div
            className={clsx(
              flexGridClasses.group,
              flexGridClasses.flexDirectionColumn
            )}
          >
            {title && (
              <h2 className={clsx(classes.fontCommon, classes.title)}>
                {title}
              </h2>
            )}

            {text && <p className={clsx(classes.text)}>{text}</p>}
          </div>
        )}

        {children}

        {mode === "popup" && renderIcon && renderIcon}
      </main>

      {renderCta && <footer className={ctaClasses}>{renderCta}</footer>}
    </article>
  )
}

export default Base
