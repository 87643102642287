import React from "react"
import { IIconProps } from "./types"
import IconWrapper from "./IconWrapper"

// Tw = Twitter Emoji Icon
const TwRelaxedFace: React.FC<IIconProps> = ({
  height = "28px",
  width = "28px",
  className,
}) => (
  <IconWrapper className={className}>
    <svg width={width} height={height} viewBox="0 0 36 36">
      <g clipPath="url(#clip0_26070_128194)">
        <path
          d="M36 18C36 27.941 27.941 36 18 36C8.059 36 0 27.941 0 18C0 8.059 8.059 0 18 0C27.941 0 36 8.059 36 18Z"
          fill="#FFCC4D"
        />
        <path
          d="M7 23C9.76142 23 12 20.7614 12 18C12 15.2386 9.76142 13 7 13C4.23858 13 2 15.2386 2 18C2 20.7614 4.23858 23 7 23Z"
          fill="#FF7892"
        />
        <path
          d="M29 23C31.7614 23 34 20.7614 34 18C34 15.2386 31.7614 13 29 13C26.2386 13 24 15.2386 24 18C24 20.7614 26.2386 23 29 23Z"
          fill="#FF7892"
        />
        <path
          d="M27.3348 21.629C27.1568 21.468 26.8908 21.458 26.6998 21.6C26.6608 21.629 22.7778 24.5 17.9998 24.5C13.2338 24.5 9.33784 21.629 9.29984 21.6C9.10884 21.458 8.84284 21.47 8.66484 21.629C8.48784 21.789 8.44784 22.053 8.57084 22.257C8.69984 22.472 11.7878 27.5 17.9998 27.5C24.2118 27.5 27.3008 22.472 27.4288 22.257C27.5518 22.052 27.5128 21.789 27.3348 21.629ZM7.99884 15C7.84884 15 7.69584 14.966 7.55284 14.894C7.05884 14.647 6.85884 14.046 7.10584 13.552C7.15784 13.448 8.42384 11 11.9998 11C15.5768 11 16.8418 13.449 16.8938 13.553C17.1408 14.047 16.9408 14.648 16.4468 14.895C15.9548 15.14 15.3618 14.944 15.1108 14.459C15.0678 14.379 14.2808 13 11.9998 13C9.68284 13 8.90084 14.433 8.89384 14.447C8.71884 14.798 8.36584 15 7.99884 15ZM28.0008 15C27.6338 15 27.2808 14.798 27.1048 14.447C27.0798 14.401 26.2988 13 23.9998 13C21.7008 13 20.9198 14.401 20.8878 14.46C20.6278 14.941 20.0288 15.13 19.5428 14.88C19.0578 14.628 18.8608 14.041 19.1048 13.552C19.1568 13.449 20.4228 11 23.9998 11C27.5768 11 28.8428 13.449 28.8948 13.553C29.1418 14.047 28.9418 14.648 28.4478 14.895C28.3038 14.966 28.1508 15 28.0008 15Z"
          fill="#664500"
        />
      </g>
      <defs>
        <clipPath id="clip0_26070_128194">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
)

export default TwRelaxedFace
