import useSWRMutation from "swr/mutation"
import { IFetchWithConfigOptions, fetchWithConfig } from "../../../utils/rest"
import { getRuntimeConfig } from "../../../utils/runtimeConfig"
import { IReviewsRequests } from "../../../types"

interface IAdvisorReviewPayload {
  reviews_request_id: number
  rating: number
  title: string | undefined
  text: string | undefined
}

interface IUpdateReviewPayload {
  id: number
  status: IReviewsRequests["status"]
}

const ENDPOINTS = {
  PUBLISH: "advisor/review/publish",
  UPDATE_STATUS: "review-request/update-status",
}

const useRatingApi = () => {
  const { trigger: publishReview } = useSWRMutation(
    ENDPOINTS.PUBLISH,
    (
      url,
      { arg: data, headers }: IFetchWithConfigOptions<IAdvisorReviewPayload>
    ) => {
      return fetchWithConfig(
        {
          url,
          method: "POST",
          data,
          headers,
        },
        getRuntimeConfig
      )
    }
  )

  const { trigger: updateReviewStatus } = useSWRMutation(
    ENDPOINTS.UPDATE_STATUS,
    (
      url,
      { arg: data, headers }: IFetchWithConfigOptions<IUpdateReviewPayload>
    ) => {
      return fetchWithConfig(
        {
          url,
          method: "PATCH",
          data,
          headers,
        },
        getRuntimeConfig
      )
    }
  )

  return {
    publishReview,
    updateReviewStatus,
  }
}

export default useRatingApi
